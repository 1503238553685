
export default [
    {
        path: '/bot',
        component: "__base",
        otherdata: { color: "primary" },
        children: [
            {
                path: "ipconfig",
                component: "InputParameterConfig.vue",
                meta: { title: "Input Parameters Config List" },
            },
            {
                path: "project",
                component: "ProjectList.vue",
                meta: { title: "Bot Project List" },
            },
            {
                path: "project/view/:botID",
                component: "ViewProject.vue",
                meta: { title: "View Bot Project" },
            },
            {
                path: "filemaster",
                component: "FileMaster.vue",
                meta: { title: "File Master" },
            },
            {
                path: "filemaster/config/:conid",
                component: "FileMasterConfig.vue",
                meta: { title: "File Master Config" },
            },
            {
                path: "filelist",
                component: "FileListConfig.vue",
                meta: { title: "File List" },
            },
            {
                path: "filelist/config/:configID?",
                component: "FileConfig.vue",
                meta: { title: "File config List" },
            },
            {
                path: "queries", 
                component: "common/QueriesList.vue",
                meta: { title: "Queries" , right:"helpdesk"}, 

            },
            {
                path: "queriesview/:QueryId",
                component: "common/QueriesView.vue",
                meta: { title: "Queries Conversations", right:"helpdesk" },
            },
            {
                path: "*",
                component: "",
            },
        ],
    },
]

